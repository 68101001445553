import { useRef } from 'react';
import type { InputRef } from 'antd';
import { Avatar, Button, Input, Space, Tag } from 'antd';
import Highlighter from 'react-highlight-words';

import { SearchOutlined } from '@ant-design/icons';

import { RangeDatePickerFilter } from '../components/sharedComponents/tableFilters/RangeDatePickerFilter';
import type { StatusKey } from '../enum';
import { Status } from '../enum';
import { useAppDispatch, useAppSelector } from '../hooks';
import { useCapturePostHog } from '../hooks/useCapturePostHog';
import type { Client, RiskHistory } from '../models/client';
import type { ClearFilters, Confirm, FilteredInfo, GetColumnSearchProps, SelectedKeys } from '../models/table';
import { handleResetSearch, handleSearchSlice } from './tableSearchSlice';

export const defaultSort = (a: number | string, b: number | string) => {
  if (a < b) return -1;
  if (b < a) return 1;
  return 0;
};

export const sortStatus = (a: StatusKey, b: StatusKey) => {
  if (Status[a] < Status[b]) return -1;
  else if (Status[b] < Status[a]) return 1;
  else return 0;
};

const diaryLine = <Tag color="#fff" style={{ height: 11, width: 45, margin: 0 }}></Tag>;

export const currentRiskComponent = (status: string, client: Client) => {
  const risk = statusFormat(status);
  let diary = null;
  if (client.risk_history && client.risk_history[0] && client.status !== 'na') {
    diary = client.risk_history[0].diary;
  }
  if (!!diary) {
    diary = diaryLine;
  }
  return (
    <Avatar style={{ backgroundColor: risk.color }} size={20}>
      {diary}
    </Avatar>
  );
};

export const riskHistoryComponent = (riskHistory: RiskHistory[], numberToShow: number) => {
  if (riskHistory) {
    return (
      <div>
        {riskHistory.slice(0, numberToShow).map((entry, index) => {
          const risk = statusFormat(entry.status);
          let journal;
          if (!!entry.diary) {
            journal = diaryLine;
          }
          return (
            <span key={index}>
              <Avatar style={{ backgroundColor: risk.color, marginRight: 2 }} size={20}>
                {journal}
              </Avatar>
            </span>
          );
        })}
      </div>
    );
  }
};

type Risk = {
  color: string;
  name: string;
};

export const statusFormat = (status: string | undefined): Risk => {
  const risk = {
    color: '',
    name: '',
  };
  if (status === 'na') {
    risk.color = '#8c8989';
    risk.name = 'Not logged';
  }
  if (status === null) {
    risk.color = '#DFDFDF';
    risk.name = 'No entry';
  }
  if (status === 'green') {
    risk.color = '#81d135';
    risk.name = 'Low';
  }
  if (status === 'yellow') {
    risk.color = '#f3a536';
    risk.name = 'Medium';
  }
  if (status === 'red') {
    risk.color = '#ce0b24';
    risk.name = 'High';
  }
  return risk;
};

export const plainTagFormat = (mood: string) => {
  return (
    <Tag color="" style={{ border: 'none' }}>
      {mood}
    </Tag>
  );
};

export const sentimentFormat = (sentiment: string) => {
  let color = '';

  if (sentiment === 'Positive') {
    color = 'green';
  }
  if (sentiment === 'Negative') {
    color = 'red';
  }
  return (
    <Tag color={color} style={{ border: 'none' }}>
      {sentiment}
    </Tag>
  );
};

export const callBackFormat = (callBack: string) => {
  let color = '';
  let title = '';

  if (callBack === 'Yes') {
    color = 'red';
    title = 'Yes';
  }
  return (
    <Tag color={color} style={{ border: 'none' }}>
      {title}
    </Tag>
  );
};

export const countData = (data: any[], filter: string | number) => {
  if (data.length > 0) {
    const filterCount = data.reduce(function (n, client) {
      return n + (client[filter] === true);
    }, 0);

    return filterCount;
  }
  return 0;
};

export const removeEmpty = (obj: FilteredInfo) => {
  if (!obj) return {};
  Object.keys(obj).forEach((key) => obj[key] == null && delete obj[key]);
  return obj;
};

const dateColumnsTableNewService = [
  'last_check_in',
  'last_intervention',
  'created',
  'updated_timestamp',
  'date',
  'timestamp',
  'submission_time',
  'available_date',
  'due_date',
  'enrollment_date',
];

export const TableService = () => {
  const dispatch = useAppDispatch();
  const ref = useRef<InputRef>(null);
  const { capturePostHogCustomEvent } = useCapturePostHog();
  const searchText = useAppSelector((state) => state.tableSearchSlice.searchText);
  const searchedColumn = useAppSelector((state) => state.tableSearchSlice.searchedColumn);

  const getColumnSearchProps: GetColumnSearchProps = (dataIndex, savedSearch?) => ({
    filterDropdown: ({ selectedKeys, setSelectedKeys, confirm, clearFilters }) => {
      const filterDropdownComponent = dateColumnsTableNewService.includes(dataIndex) ? (
        <RangeDatePickerFilter
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          confirm={confirm}
          clearFilters={() => handleReset(clearFilters, confirm, dataIndex)}
          showFutureDates={dataIndex === 'available_date' || dataIndex === 'due_date' ? true : false}
        />
      ) : (
        <div style={{ padding: 8 }}>
          <Input
            ref={ref}
            placeholder={`Search ${dataIndex}`}
            defaultValue={savedSearch}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters, confirm, dataIndex)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      );
      return filterDropdownComponent;
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => ref.current?.select(), 100);
      }
    },
    render: (text) => {
      // TODO: Check waht is happening here to see if can be improved
      const savedSearchText = savedSearch?.[0];
      return searchedColumn === dataIndex || savedSearchText !== undefined ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText || savedSearchText || '']}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      );
    },
  });

  const handleSearch = (selectedKeys: SelectedKeys, confirm: Confirm, dataIndex: string) => {
    confirm();
    dispatch(handleSearchSlice({ selectedKeys, dataIndex }));
    capturePostHogCustomEvent(`TableService search ${dataIndex} column`);
  };

  const handleReset = (clearFilters: ClearFilters, confirm: Confirm, dataIndex: string) => {
    clearFilters?.();
    confirm();
    dispatch(handleResetSearch());
    capturePostHogCustomEvent(`TableService reset ${dataIndex} column`);
  };

  const clearSearch = () => {
    dispatch(handleResetSearch());
  };

  return { getColumnSearchProps, clearSearch };
};
