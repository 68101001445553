import { notification } from 'antd';
import type { AxiosError } from 'axios';
import axios from 'axios';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import type { UserRole } from '../../../enum';
import { APIStatus } from '../../../enum';
import type { ErrorMessage } from '../../../models/error';
import type { AsyncThunkConfig } from '../../../models/slice';
import type { FilteredInfo, RequestParams, TableChange } from '../../../models/table';
import { SortedInfo } from '../../../models/table';
import { RaygunErrorHandlerService } from '../../../service/raygun.service';
import { removeEmpty } from '../../../service/table.service';
import { validateAdminClinician } from './admin-clinicians.helper';
import type { ResetPassword } from './adminClinicianPasswordReset';

const { logError } = RaygunErrorHandlerService();

export class AdminClinician {
  id: string | null = null;
  first_name: string | null = null;
  last_name: string | null = null;
  email: string | null = null;
  account_status: string | null = null;
  mobile_phone: string | null = null;
  organization: string | null = null;
  organization_id: string | null = null;
  role: UserRole | null = null;
  sites: string[] | null = null;
  site_ids: string[] | null = null;
  job_id: string | null = null;
  job_name: string | null = null;
  created: string | null = null;
  full_name: string | null = null;
  internal: boolean = false;
  organization_internal: boolean | null = null;
  total_count: number = 0;
  receive_alerts: boolean = false;
}

type ClinicianJob = {
  id: string;
  name: string;
  organization_id: string;
};

export type ClinicianSite = {
  site_id: string;
  name: string;
  assigned: boolean;
};

export enum ADMIN_CLINICIAN_MODAL {
  PASSWORD_RESET = 'password_reset',
  IDLE = 'idle',
  EDIT_CLINICIAN = 'edit_clinician',
}

type AdminClinicianSliceType = {
  adminClinicianData: AdminClinician[];
  clinicianJobs: ClinicianJob[];
  clinicianSites: ClinicianSite[];
  requestAdminClinicianParam: RequestParams<AdminClinician>;
  adminApiStatus: APIStatus;
  adminAPIErrorText: string;
  adminClinicianModal: ADMIN_CLINICIAN_MODAL;
  adminClinician: AdminClinician;
};

const initialState: AdminClinicianSliceType = {
  adminClinicianData: [],
  clinicianJobs: [],
  clinicianSites: [],
  requestAdminClinicianParam: {
    pagination: {
      current: 1,
      pageSize: 50,
      defaultPageSize: 50,
    },
    sortedInfo: new SortedInfo<AdminClinician>('last_name', 'ascend'),
    filteredInfo: {},
  },
  adminApiStatus: APIStatus.IDLE,
  adminAPIErrorText: '',
  adminClinicianModal: ADMIN_CLINICIAN_MODAL.IDLE,
  adminClinician: new AdminClinician(),
};

export const fetchAdminClinicians = createAsyncThunk<AdminClinician[], undefined, AsyncThunkConfig>(
  'adminClinician/fetchAdminClinicians',
  async (_, thunkAPI) => {
    try {
      const { adminClinicianSlice } = thunkAPI.getState();
      const response = await axios.post('v2_admin_clinician', adminClinicianSlice.requestAdminClinicianParam);
      return (response as unknown as AdminClinician[]) ?? [];
    } catch (e) {
      logError(e, ['adminClinicianSlice', 'fetchAdminClinicians']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const adminCliniciansPasswordReset = createAsyncThunk<string, ResetPassword, AsyncThunkConfig>(
  'adminClinician/adminCliniciansPasswordReset',
  async (resetPassword, thunkAPI) => {
    try {
      const response = await axios.post('v2_admin_clinician_password_reset', resetPassword);
      if (response) {
        notification.destroy();
        notification.success({
          message: 'Success',
          description: `Password set successfully.`,
        });
      }
      return response as unknown as string; // clinician_id
    } catch (e) {
      logError(e, ['adminClinicianSlice', 'adminCliniciansPasswordReset']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const fetchClinicianJobs = createAsyncThunk<ClinicianJob[], undefined, AsyncThunkConfig>(
  'adminClinician/fetchClinicianJobs',
  async (_, thunkAPI) => {
    try {
      const response = await axios.get('v3_clinician_jobs');
      return (response as unknown as ClinicianJob[]) ?? [];
    } catch (e) {
      logError(e, ['adminClinicianSlice', 'fetchClinicianJobs']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

type SetInternal = {
  clinician_id: string;
  internal: boolean;
};

export const adminClinicianSetInternal = createAsyncThunk<string, SetInternal, AsyncThunkConfig>(
  'adminClinician/adminClinicianSetInternal',
  async (setInternal, thunkAPI) => {
    try {
      const response = await axios.post('v2_clinician_internal_set', setInternal);
      if (response) {
        thunkAPI.dispatch(fetchAdminClinicians());
      }
      return response as unknown as string; // clinician_id
    } catch (e) {
      logError(e, ['adminClinicianSlice', 'adminClinicianSetInternal']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const fetchClinicianSites = createAsyncThunk<ClinicianSite[], string, AsyncThunkConfig>(
  'adminClinician/fetchClinicianSites',
  async (clinician_id, thunkAPI) => {
    try {
      const response = await axios.post('v2_admin_clinician_site', { clinician_id });
      return (response as unknown as ClinicianSite[]) ?? [];
    } catch (e) {
      logError(e, ['adminClinicianSlice', 'fetchClinicianSites']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const upsertAdminClinicians = createAsyncThunk<string, AdminClinician, AsyncThunkConfig>(
  'adminClinician/upsertAdminClinicians',
  async (upsertAdminClinician, thunkAPI) => {
    try {
      if (validateAdminClinician(upsertAdminClinician)) {
        const response = await axios.post('v2_admin_clinician_upsert', upsertAdminClinician);
        if (response) {
          notification.destroy();
          notification.success({
            message: 'Clinician updated successfully',
            description: 'Success',
          });
          thunkAPI.dispatch(fetchAdminClinicians());
          thunkAPI.dispatch(closeAdminClinicianModal());
        }
        return response as unknown as string; // clinician_id
      }
      return '';
    } catch (e) {
      logError(e, ['adminClinicianSlice', 'upsertAdminClinicians']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const adminClinicianSlice = createSlice({
  name: 'adminClinician',
  initialState,
  reducers: {
    handleClinicianTableChange: (state, action: PayloadAction<TableChange<AdminClinician>>) => {
      const filters = action.payload.filters as FilteredInfo;
      const sorter = action.payload.sorter as SortedInfo<AdminClinician>;
      if (!sorter.order) {
        sorter.order = null;
      }
      state.requestAdminClinicianParam.pagination = action.payload.pagination;
      state.requestAdminClinicianParam.sortedInfo = sorter;
      state.requestAdminClinicianParam.filteredInfo = removeEmpty(filters);
    },
    clearFilter: (state) => {
      state.requestAdminClinicianParam.pagination = initialState.requestAdminClinicianParam.pagination;
      state.requestAdminClinicianParam.sortedInfo = initialState.requestAdminClinicianParam.sortedInfo;
      state.requestAdminClinicianParam.filteredInfo = initialState.requestAdminClinicianParam.filteredInfo;
    },
    setAdminClinicianModal: (state, action: PayloadAction<ADMIN_CLINICIAN_MODAL>) => {
      state.adminClinicianModal = action.payload;
    },
    setAdminClinician: (state, action: PayloadAction<AdminClinician>) => {
      state.adminClinician = action.payload;
    },
    closeAdminClinicianModal: (state) => {
      state.adminClinicianModal = ADMIN_CLINICIAN_MODAL.IDLE;
      state.adminClinician = new AdminClinician();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdminClinicians.pending, (state, _action) => {
        state.adminApiStatus = APIStatus.PENDING;
      })
      .addCase(fetchAdminClinicians.fulfilled, (state, action) => {
        state.adminClinicianData = action.payload;
        state.adminApiStatus = APIStatus.FULFILLED;
      })
      .addCase(fetchAdminClinicians.rejected, (state, action) => {
        state.adminApiStatus = APIStatus.ERROR;
        state.adminAPIErrorText = (action.payload as ErrorMessage)?.displayText;
      })
      .addCase(adminCliniciansPasswordReset.fulfilled, (state) => {
        state.adminClinicianModal = ADMIN_CLINICIAN_MODAL.IDLE;
      })
      .addCase(fetchClinicianJobs.fulfilled, (state, action) => {
        state.clinicianJobs = action.payload;
      })
      .addCase(fetchClinicianSites.fulfilled, (state, action) => {
        state.clinicianSites = action.payload;
      });
  },
});

export const {
  handleClinicianTableChange,
  clearFilter,
  setAdminClinicianModal,
  setAdminClinician,
  closeAdminClinicianModal,
} = adminClinicianSlice.actions;
