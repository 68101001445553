import { Navigate, useLocation } from 'react-router-dom';

import { PageHeader } from '@ant-design/pro-layout';

import type { UserRole } from '../enum';
import { useAppSelector } from '../hooks';

export const RequireAuth = ({ requiredRoles, children }: { requiredRoles: UserRole[]; children: JSX.Element }) => {
  const isLoggedIn = useAppSelector((state) => Boolean(state.authSlice.authResult.token));
  const userHasRequiredRole = useAppSelector(
    (state) => state.authSlice.currentUser.role && requiredRoles.includes(state.authSlice.currentUser.role),
  );
  const location = useLocation();

  if (isLoggedIn && userHasRequiredRole) {
    return children;
  } else if (isLoggedIn) {
    return (
      <PageHeader ghost={false} title="Page not found">
        <p>Please select a page from the navigation bar on the left.</p>
      </PageHeader>
    );
  }

  return <Navigate to="/login" state={{ from: location }} />;
};
