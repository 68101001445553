import { lazy } from 'react';
import type { Location, RouteObject } from 'react-router-dom';
import { redirect } from 'react-router-dom';

import { MainLayout } from '../components/layouts/layout';
import type { AuthResult, CurrentUser } from '../components/pages/Login/authSlice';
import { resetPasswordLoaderWithStore } from '../components/pages/reset-password/reset-password.helper';
import { UserOrganization, UserRole } from '../enum';
import { ErrorBoundary } from '../errorBoundary';
import type { AppDispatch } from '../models/slice';
import store from '../store';
import CapturePostHogPageViewComponent from './CapturePostHogPageView';
import { RequireAuth } from './RequireAuth';
import { SuspenseRoute } from './SuspenseRoute';

const AdminAddProductComponent = lazy(() => import('../components/pages/admin-add-product/admin-add-product'));
const AdminClientProfileComponent = lazy(() => import('../components/pages/client-profile/admin-client-profile'));
const AdminClientsComponent = lazy(() => import('../components/pages/admin-clients/admin-clients'));
const AdminClinicianComponent = lazy(() => import('../components/pages/admin-clinicians/admin-clinicians'));
const AdminJournalsComponent = lazy(() => import('../components/pages/admin-journals/admin-journals'));
const AdminOrganizationComponent = lazy(() => import('../components/pages/admin-organization/admin-organization'));
const AdminSiteComponent = lazy(() => import('../components/pages/admin-sites/admin-sites'));
const ClientProfileComponent = lazy(() => import('../components/pages/client-profile/client-profile'));
const ClientUpsertComponent = lazy(() => import('../components/pages/client-upsert/client-upsert'));
const ClinicianDashboardComponent = lazy(() => import('../components/pages/clinician-dashboard/clinician-dashboard'));
const ClinicianSettingsComponent = lazy(() => import('../components/pages/clinician-settings/clinician-settings'));
const DashboardComponent = lazy(() => import('../components/pages/dashboard/dashboard'));
const ForgotPasswordComponent = lazy(() => import('../components/pages/ForgotPassword/forgot'));
const HHCWeekendScheduleComponent = lazy(() => import('../components/pages/hhc-weekend-schedule/hhc-weekend-schedule'));
const InActiveClientComponent = lazy(() => import('../components/pages/inactive-clients/inactive-clients'));
const InterventionComponent = lazy(() => import('../components/pages/intervention/intervention'));
const LoginComponent = lazy(() => import('../components/pages/Login/login'));
const ProgramDashboardComponent = lazy(() => import('../components/pages/program-dashboard/program-dashboard'));
const ReportComponent = lazy(() => import('../components/pages/report/report'));
const ResetPasswordComponent = lazy(() => import('../components/pages/reset-password/reset-password'));
const ResourcesComponent = lazy(() => import('../components/pages/resources/resources'));
const ScheduledProductComponent = lazy(() => import('../components/pages/scheduled-products/scheduled-products'));
const ValidationComponent = lazy(() => import('../components/pages/validation/validation'));
const WeeklyCompletionComponent = lazy(
  () => import('../components/pages/weekly-completion-yale/weekly-completion-yale'),
);

export const redirectPage = (currentUser: CurrentUser, previousPath?: Location) => {
  if (previousPath?.pathname.includes('client-profile')) {
    return previousPath.pathname;
  }
  if (
    currentUser.role === UserRole.ADMIN ||
    (currentUser.role === UserRole.PROGRAM_ADMINISTRATION && currentUser.organization_id === UserOrganization.YALE)
  ) {
    return '/page/report';
  } else if (currentUser.role === UserRole.PROGRAM_ADMINISTRATION) {
    return '/page/program-dashboard';
  } else {
    return '/page/home';
  }
};

export const createRoute = (currentUser: CurrentUser, authResult: AuthResult, dispatch: AppDispatch): RouteObject[] => {
  const pageChildren = [
    { index: true, loader: () => redirect(redirectPage(currentUser).replace('/page/', '')) },
    { path: '*', loader: () => redirect(redirectPage(currentUser).replace('/page/', '')) },
    {
      path: 'home',
      element: (
        <SuspenseRoute dispatch={dispatch}>
          <RequireAuth requiredRoles={[UserRole.CLINICIAN, UserRole.ORGANIZATION, UserRole.SITE]}>
            <DashboardComponent />
          </RequireAuth>
        </SuspenseRoute>
      ),
    },
    {
      path: 'client-profile/:client_id',
      element: (
        <SuspenseRoute dispatch={dispatch}>
          <RequireAuth requiredRoles={[UserRole.CLINICIAN, UserRole.ORGANIZATION, UserRole.SITE]}>
            <ClientProfileComponent />
          </RequireAuth>
        </SuspenseRoute>
      ),
    },
    {
      path: 'admin-client-profile/:client_id',
      element: (
        <SuspenseRoute dispatch={dispatch}>
          <RequireAuth requiredRoles={[UserRole.ADMIN]}>
            <AdminClientProfileComponent />
          </RequireAuth>
        </SuspenseRoute>
      ),
    },
    {
      path: 'admin-organizations',
      element: (
        <SuspenseRoute dispatch={dispatch}>
          <RequireAuth requiredRoles={[UserRole.ADMIN]}>
            <AdminOrganizationComponent />
          </RequireAuth>
        </SuspenseRoute>
      ),
    },
    {
      path: 'admin-products',
      element: (
        <SuspenseRoute dispatch={dispatch}>
          <RequireAuth
            requiredRoles={[
              UserRole.ADMIN,
              UserRole.CLINICIAN,
              UserRole.ORGANIZATION,
              UserRole.SITE,
              UserRole.PROGRAM_ADMINISTRATION,
            ]}
          >
            <AdminJournalsComponent />
          </RequireAuth>
        </SuspenseRoute>
      ),
    },
    {
      path: 'resources',
      element: (
        <SuspenseRoute dispatch={dispatch}>
          <RequireAuth
            requiredRoles={[
              UserRole.ADMIN,
              UserRole.CLINICIAN,
              UserRole.ORGANIZATION,
              UserRole.SITE,
              UserRole.PROGRAM_ADMINISTRATION,
            ]}
          >
            <ResourcesComponent />
          </RequireAuth>
        </SuspenseRoute>
      ),
    },
    {
      path: 'clinician-settings',
      element: (
        <SuspenseRoute dispatch={dispatch}>
          <RequireAuth
            requiredRoles={[
              UserRole.CLINICIAN,
              UserRole.ORGANIZATION,
              UserRole.SITE,
              UserRole.ADMIN,
              UserRole.PROGRAM_ADMINISTRATION,
            ]}
          >
            <ClinicianSettingsComponent />
          </RequireAuth>
        </SuspenseRoute>
      ),
    },
    {
      path: 'client-onboarding',
      element: (
        <SuspenseRoute dispatch={dispatch}>
          <RequireAuth
            requiredRoles={[
              UserRole.CLINICIAN,
              UserRole.ORGANIZATION,
              UserRole.SITE,
              UserRole.ADMIN,
              UserRole.PROGRAM_ADMINISTRATION,
            ]}
          >
            <ClientUpsertComponent />
          </RequireAuth>
        </SuspenseRoute>
      ),
    },
    {
      path: 'program-dashboard',
      element: (
        <SuspenseRoute dispatch={dispatch}>
          <RequireAuth
            requiredRoles={[
              UserRole.PROGRAM_ADMINISTRATION,
              UserRole.ADMIN,
              UserRole.CLINICIAN,
              UserRole.ORGANIZATION,
              UserRole.SITE,
            ]}
          >
            <ProgramDashboardComponent />
          </RequireAuth>
        </SuspenseRoute>
      ),
    },
    {
      path: 'scheduled-products',
      element: (
        <SuspenseRoute dispatch={dispatch}>
          <RequireAuth requiredRoles={[UserRole.ADMIN, UserRole.CLINICIAN, UserRole.SITE, UserRole.ORGANIZATION]}>
            <ScheduledProductComponent />
          </RequireAuth>
        </SuspenseRoute>
      ),
    },
    {
      path: 'clinician-dashboard',
      element: (
        <SuspenseRoute dispatch={dispatch}>
          <RequireAuth requiredRoles={[UserRole.ADMIN, UserRole.ORGANIZATION]}>
            <ClinicianDashboardComponent />
          </RequireAuth>
        </SuspenseRoute>
      ),
    },
    {
      path: 'interventions',
      element: (
        <SuspenseRoute dispatch={dispatch}>
          <RequireAuth requiredRoles={[UserRole.ADMIN, UserRole.ORGANIZATION]}>
            <InterventionComponent />
          </RequireAuth>
        </SuspenseRoute>
      ),
    },
    {
      path: 'validation',
      element: (
        <SuspenseRoute dispatch={dispatch}>
          <RequireAuth requiredRoles={[UserRole.ADMIN, UserRole.ORGANIZATION]}>
            <ValidationComponent />
          </RequireAuth>
        </SuspenseRoute>
      ),
    },
    {
      path: 'inactive-clients',
      element: (
        <SuspenseRoute dispatch={dispatch}>
          <RequireAuth requiredRoles={[UserRole.ADMIN, UserRole.ORGANIZATION]}>
            <InActiveClientComponent />
          </RequireAuth>
        </SuspenseRoute>
      ),
    },
    {
      path: 'weekly-completion-yale',
      element: (
        <RequireAuth requiredRoles={[UserRole.ORGANIZATION]}>
          <WeeklyCompletionComponent />
        </RequireAuth>
      ),
    },
    {
      path: 'admin-clients',
      element: (
        <SuspenseRoute dispatch={dispatch}>
          <RequireAuth requiredRoles={[UserRole.ADMIN, UserRole.ORGANIZATION, UserRole.SITE]}>
            <AdminClientsComponent />
          </RequireAuth>
        </SuspenseRoute>
      ),
    },
    {
      path: 'admin-clinicians',
      element: (
        <SuspenseRoute dispatch={dispatch}>
          <RequireAuth
            requiredRoles={[UserRole.ADMIN, UserRole.ORGANIZATION, UserRole.SITE, UserRole.PROGRAM_ADMINISTRATION]}
          >
            <AdminClinicianComponent />
          </RequireAuth>
        </SuspenseRoute>
      ),
    },
    {
      path: 'admin-sites',
      element: (
        <SuspenseRoute dispatch={dispatch}>
          <RequireAuth requiredRoles={[UserRole.ADMIN, UserRole.ORGANIZATION]}>
            <AdminSiteComponent />
          </RequireAuth>
        </SuspenseRoute>
      ),
    },
    {
      path: 'report',
      element: (
        <SuspenseRoute dispatch={dispatch}>
          <RequireAuth requiredRoles={[UserRole.ADMIN, UserRole.ORGANIZATION, UserRole.PROGRAM_ADMINISTRATION]}>
            <ReportComponent />
          </RequireAuth>
        </SuspenseRoute>
      ),
    },
    {
      path: 'clients',
      element: (
        <SuspenseRoute dispatch={dispatch}>
          <RequireAuth requiredRoles={[UserRole.CLINICIAN]}>
            <AdminClientsComponent />
          </RequireAuth>
        </SuspenseRoute>
      ),
    },
    {
      path: 'hhc-weekend-schedule',
      element: (
        <SuspenseRoute dispatch={dispatch}>
          <RequireAuth requiredRoles={[UserRole.ADMIN]}>
            <HHCWeekendScheduleComponent />
          </RequireAuth>
        </SuspenseRoute>
      ),
    },
    {
      path: 'admin-add-product',
      element: (
        <SuspenseRoute dispatch={dispatch}>
          <RequireAuth requiredRoles={[UserRole.ADMIN]}>
            <AdminAddProductComponent />
          </RequireAuth>
        </SuspenseRoute>
      ),
    },
  ];

  const filteredPageChildren = pageChildren.filter((c) => {
    switch (c?.path) {
      case 'program-dashboard':
        return currentUser.organization_id !== UserOrganization.YALE;
      case 'scheduled-products':
        return currentUser.organization_id !== UserOrganization.YALE && authResult.use_scheduler;
      case 'clinician-dashboard':
        return authResult.use_clinician_dashboard;
      default:
        return true;
    }
  });

  return [
    { index: true, loader: () => redirect(redirectPage(currentUser)) },
    { path: '*', loader: () => redirect(redirectPage(currentUser)) },
    {
      path: '/login',
      element: (
        <SuspenseRoute dispatch={dispatch}>
          <CapturePostHogPageViewComponent>
            <LoginComponent />
          </CapturePostHogPageViewComponent>
        </SuspenseRoute>
      ),
    },
    {
      path: '/forgot-password',
      element: (
        <SuspenseRoute dispatch={dispatch}>
          <CapturePostHogPageViewComponent>
            <ForgotPasswordComponent />
          </CapturePostHogPageViewComponent>
        </SuspenseRoute>
      ),
    },
    {
      path: '/auth/reset/:token',
      element: (
        <SuspenseRoute dispatch={dispatch}>
          <CapturePostHogPageViewComponent>
            <ResetPasswordComponent />
          </CapturePostHogPageViewComponent>
        </SuspenseRoute>
      ),
      loader: resetPasswordLoaderWithStore(store),
    },
    {
      path: '/page',
      element: (
        <ErrorBoundary dispatch={dispatch}>
          <RequireAuth
            requiredRoles={[
              UserRole.ADMIN,
              UserRole.CLINICIAN,
              UserRole.ORGANIZATION,
              UserRole.SITE,
              UserRole.PROGRAM_ADMINISTRATION,
            ]}
          >
            <CapturePostHogPageViewComponent>
              <MainLayout />
            </CapturePostHogPageViewComponent>
          </RequireAuth>
        </ErrorBoundary>
      ),
      children: filteredPageChildren,
    },
  ];
};
