import type { StatusKey } from '../enum';

export class Client {
  first_name: string = '';
  id: string = '';
  last_check_in: string = '';
  last_name: string = '';
  my_client: boolean = false;
  status: string = '';
  is_diary: boolean = false;
  risk_history: RiskHistory[] = [];
  sites?: any;
  last_intervention: string = '';
  diagnoses?: any;
  sentiment?: any;
  name: string = '';
  total_count: number = 0;
  high_risk_count: number | null = null;
  medium_risk_count: number | null = null;
  never_journaled_count: number | null = null;
  low_risk_count: number | null = null;
  na_risk_count: number | null = null;
}

export type MeasurementQuantity = {
  unit: string;
  value: number;
};

export type Measurement = {
  bmi: number;
  weight_in_kg: number;
  height_in_cm: number;
  diastolic_blood_pressure: number;
  systolic_blood_pressure: number;
};

export type RiskHistory = {
  status: string;
  score: number;
  sentiment: number;
  date: string;
  diary: string;
  last_callback: boolean;
  mood: string;
};

export type ScoreStatus = {
  score: number;
  status: StatusKey;
};

export type ScoreDetail =
  | {
      product: 'BAM-17';
      Use: ScoreStatus;
      Risk: ScoreStatus;
      Protective: Omit<ScoreStatus, 'status'>;
      Ratio: ScoreStatus;
    }
  /** for OnTrak ReQoL10a and ReQoL10b products */
  | {
      product: 'OnTrak';
      variation: number;
    }
  | {
      product: 'TEA';
      Use: ScoreStatus;
      Health: ScoreStatus;
      Lifestyle: ScoreStatus;
      Community: ScoreStatus;
    }
  | {
      product: 'TAPS';
      Tobacco: ScoreStatus;
      Alcohol: ScoreStatus;
      Cannabis: ScoreStatus;
      Stimulant: ScoreStatus;
    }
  | {
      product: 'PCL-5';
      'Re-experiencing': ScoreStatus;
      Avoidance: ScoreStatus;
      'Cognition/mood': ScoreStatus;
      'Hyper-arousal': ScoreStatus;
    }
  | {
      product: 'PHENX';
      label: string;
      status: StatusKey;
    }
  | {
      product: 'WHODAS';
      status: string;
      domains: { score: number; status: string; label: string; result: string }[];
    }
  | {
      product: 'C-SSRS';
      status: string;
      questions: { score: number; status: string; response: string; label: string }[];
    }
  | {
      product: 'DSM5';
      Depression: ScoreStatus;
      Anger: ScoreStatus;
      Mania: ScoreStatus;
      Somatic: ScoreStatus;
      Suicidal: ScoreStatus;
      Psychosis: ScoreStatus;
      Sleep: ScoreStatus;
      Memory: ScoreStatus;
      Repetitive: ScoreStatus;
      Dissociation: ScoreStatus;
      Personality: ScoreStatus;
      Substance: ScoreStatus;
    };

export type ProductScoreDetail<T extends ScoreDetail['product']> = {
  product: T;
} & ScoreDetail;
