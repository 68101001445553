import type { Client } from '../../../models/client';
import { isUndefinedOrNull } from '../../../service/definition-helper.service';

export const sentiment_status = (c: Client) => {
  if (
    isUndefinedOrNull(c.risk_history) ||
    isUndefinedOrNull(c.status) ||
    c.status === 'na' ||
    isUndefinedOrNull(c.risk_history[0]?.sentiment)
  )
    return null;
  if (c.risk_history[0].sentiment >= 0.3) return 'Positive';
  if (c.risk_history[0].sentiment <= -0.3) return 'Negative';
  return 'Neutral';
};
