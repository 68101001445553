import { useState } from 'react';
import { Col, Drawer, Row } from 'antd';
import { Link } from 'react-router-dom';

import { MenuUnfoldOutlined } from '@ant-design/icons';

import { useCapturePostHog } from '../../hooks/useCapturePostHog';
import { SideNavMenu } from './sidemenu';

export const HeaderComponent = () => {
  const [visible, setVisible] = useState(false);
  const { capturePostHogCustomEvent } = useCapturePostHog();

  return (
    <div>
      <Row justify="end">
        <Col flex="auto">
          <Link to="/page/home">
            <img
              src="assets/logo.png"
              alt="TetherAll"
              style={{
                width: 'auto',
                height: 31,
                padding: 2,
                marginTop: 15,
                marginLeft: 20,
              }}
            />
          </Link>
        </Col>

        <Col>
          <MenuUnfoldOutlined
            className="menuTriggerMobile"
            onClick={() => {
              setVisible(true);
              capturePostHogCustomEvent('MobileDrawer visible');
            }}
          />
        </Col>
      </Row>
      <Drawer
        title={
          <Link to="/page/home">
            <img
              src="assets/logo.png"
              alt="TetherAll"
              style={{
                width: 'auto',
                height: 28,
                marginTop: 5,
              }}
            />
          </Link>
        }
        placement="left"
        closable={true}
        className="mobileDrawer"
        open={visible}
        onClose={() => {
          setVisible(false);
          capturePostHogCustomEvent('MobileDrawer hidden');
        }}
      >
        <div>
          <SideNavMenu />
        </div>
      </Drawer>
    </div>
  );
};
