import { useEffect } from 'react';
import { notification } from 'antd';
import { browserName, browserVersion } from 'react-device-detect';

import { environment } from '../environment/environment';

const browserList = ['Chrome', 'Safari', 'Firefox', 'Edge', 'Mobile Safari'];

// version reference: https://browsersl.ist/#q=defaults%2C+node+18%2C+%3E0.2%25%2Cnot+dead%2C+not+op_mini+all
const checkBrowserOutDated = (browserName: string, browserVersion: string) => {
  switch (browserName) {
    case 'Chrome':
    case 'Firefox':
    case 'Edge':
      return browserVersion < environment.chromiumMinNumber;
    case 'Safari':
    case 'Mobile Safari':
      return browserVersion < environment.safariMinNumber;
    default:
      return true;
  }
};

export const useCheckBrowserUpdate = () => {
  const checkBrowserUpdate = () => {
    if (!browserList.includes(browserName)) {
      notification.warning({
        message: 'Browser is not supported.',
        description: `TetherAll features may not work. \n A not-supported browser may present security risks.\n Please use Chrome, Safari, Edge, or FireFox.`,
        placement: 'topLeft',
        duration: null,
      });
    } else if (checkBrowserOutDated(browserName, browserVersion)) {
      notification.warning({
        message: 'Browser is out-of-date.',
        description:
          'TetherAll features may not work. \n An out-of-date browser may present security risks.\n Please update your browser.',
        placement: 'topLeft',
        duration: null,
      });
    }
  };

  useEffect(() => {
    checkBrowserUpdate();
  }, []);
};
